
import { defineComponent } from "vue";
import Container from "../components/Structure/Container.vue";

export default defineComponent({
  name: "Error404",
  components: {
    Container,
  },
});
